import React, { FC, useCallback, useEffect, useState } from "react";
import MicroPhoneIcon from '../../icons/MicroPhone';
import Button from '../Button';
import Curtain from '../Curtain';
// import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Lang } from '../../../config/lang';
import RecordOptions from './components/RecordOptions';
import NarratorDetails from "./components/NarratorDetails";
import RoundButton from "../RoundButton";

type Props = {
    bookId: string;
    bookLangs: Lang[];
    small?: boolean;
    round?: boolean;
};

const RecordButton: FC<Props> = ({ bookId, small, round, bookLangs }) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [recordLang, setRecordLang] = useState<Lang>(bookLangs?.[0]);
    const [showNarratorDetails, setShowNarratorDetails] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setShowOptions(false);
            setShowNarratorDetails(false);
        }
    }, []);

    const { t } = useTranslation('common');

    const toggleOptions = useCallback(() => {
        setShowOptions(prev => {
            if (prev) {
                setShowNarratorDetails(false);
            }
            return !prev;
        });
    }, []);

    const handleLangChange = useCallback((lang: string) => {
        setRecordLang(lang as Lang);
    }, []);

    const toggleNarratorDetails = useCallback(() => {
        setShowNarratorDetails(prev => !prev);
    }, []);

    return (
        <>
            {round ? (
                <RoundButton icon={<MicroPhoneIcon size={24} />} onClick={toggleOptions} size='s' color='red'>
                    <MicroPhoneIcon color='white' size={24} />
                </RoundButton>
            ) : (
                <Button fullWidth size='small' textUpperCase color="red" icon={<MicroPhoneIcon />} onClick={toggleOptions}>
                    {t('record')}
                </Button>
            )}
            <Curtain open={showOptions} onClose={toggleOptions}>
                {showNarratorDetails ? (
                    <NarratorDetails bookId={bookId} recordLang={recordLang} onDone={toggleOptions} />
                ) : (
                    <RecordOptions
                        bookId={bookId}
                        bookLangs={bookLangs}
                        recordLang={recordLang}
                        onRemoteRecPress={toggleNarratorDetails}
                        handleLangChange={handleLangChange}
                    />
                )}
            </Curtain>
        </>
    );
};

export default RecordButton;
