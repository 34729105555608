import React, { FC, useContext } from 'react';
import Curtain from '../../../../components/common/Curtain';
import { Author } from '../../../../types/IBook';
import Avatar from '../../../../components/common/Avatar';
import { AvatarSize } from '../../../../components/common/Avatar/types';
import { getAuthorName } from '../../../../utils/langUtils';
import { LangContext } from '../../../../context/lang';
import { Lang } from '../../../../config/lang';
import Button from '../../../../components/common/Button';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { getIcon, getLabel } from '../../../Contributor/utils';
import { Link } from 'react-router-dom';
import routes, { routeID } from '../../../../config/routes';
import cn from "classnames";
import { getSizedImage, ImageSizesValues } from "../../../../utils/getSizedImage";

type Props = {
    open: boolean;
    onClose: () => void;
    contributors: Author[];
    bookLang: Lang;
};
const Contributors: FC<Props> = ({ open, contributors, bookLang, onClose }) => {
    const { lang } = useContext(LangContext);
    const { t } = useTranslation('book');
    if (!contributors) return null;
    return (
        <Curtain title={t('contributors')} open={open} onClose={onClose}>
            {contributors?.map(contributor => {
                const name = contributor ? getAuthorName({ author: contributor, appLang: lang, fallbackLang: bookLang }) : '';
                const sizedCover = getSizedImage(contributor?.avatar?.src, ImageSizesValues['140x140']);
                return (
                    <Link to={routes.contributor.replace(routeID, contributor.id)} key={contributor.id} className={styles.contributor}>
                        <div className={styles.avatarBox}>
                            <Avatar avatar={sizedCover?.webp} userName={name} size={AvatarSize.L} />
                        </div>
                        {name && <p>{name}</p>}
                        <div className={cn(styles.types, {[styles.hasMore]: contributor?.types.length > 1})}>
                            {contributor.types?.map(type => (
                                <Button type='button' key={type} size='xs' color="gray" icon={getIcon(type)}>
                                    <em>{getLabel(type, t)}</em>
                                </Button>
                            ))}
                        </div>
                    </Link>
                );
            })}
        </Curtain>
    );
};

export default Contributors;
