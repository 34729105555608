import React from "react";
import { AuthorType } from "../../types/IBook";
import KeyBoardIcon from "../../components/icons/KeyBoard";
import RecordVoiceIcon from "../../components/icons/RecordVoice";
import DrawIcon from "../../components/icons/Draw";
import TranslateIcon from "../../components/icons/Translate";
import EditNoteIcon from "../../components/icons/EditNote";
import AccountBalanceIcon from "../../components/icons/AccountBalance";

export const getIcon = (type: string) => {
    switch (type) {
        case AuthorType.author:
            return <KeyBoardIcon />;
        case AuthorType.narrator:
            return <RecordVoiceIcon />;
        case AuthorType.illustrator:
            return <DrawIcon />;
        case AuthorType.translator:
            return <TranslateIcon />;
        case AuthorType.editor:
            return <EditNoteIcon />;
        case AuthorType.publisher:
            return <AccountBalanceIcon />;
        default:
            return null;
    }
};

export const getLabel = (type: string, t: (key: string) => string) => {
    switch (type) {
        case AuthorType.author:
            return t('author');
        case AuthorType.narrator:
            return t('narrator');
        case AuthorType.illustrator:
            return t('illustrator');
        case AuthorType.translator:
            return t('translator');
        case AuthorType.editor:
            return t('editor');
        case AuthorType.publisher:
            return t('publisher');
        default:
            return null;
    }
};
