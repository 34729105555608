import React, { FC, useMemo } from "react";
import Button from "../../../../components/common/Button";
import { useTranslation } from "react-i18next";
import styles from './styles.module.scss';
import { ECampaignType } from "../../../../types/Sets";

type Props = {
    name: string;
    count: number;
    desc: string;
    onPress: () => void;
};

const CampaignInfo: FC<Props> = ({name, count, desc, onPress}) => {
    const { t } = useTranslation('common');

    const campaignExtraLabels = useMemo(() => {
        if (name === ECampaignType.TU) {
            return {
                label1: t('campaignLabels.Free books distributed to'),
                label2: t('campaignLabels.to locations with Ukrainian refugee children for shared use'),
            }
        }
        if (name === ECampaignType.DG) {
            return {
                label1: t('campaignLabels.Gifters like you already helped to gift'),
                label2: t('campaignLabels.books to the children in need'),
            }
        }
        if (name === ECampaignType.BSGB) {
            return {
                label1: t('campaignLabels.Donors like you already helped to gift'),
                label2: t('campaignLabels.book sets to the Ukrainian refugee families'),
            }
        }
        return null;
    }, [name, t]);

    return (
        <>
            <div className={styles.tag}>
                <span>{t(name)}</span>
            </div>
            {campaignExtraLabels && <div className={styles.label1}>{campaignExtraLabels.label1}</div>}
            <div className={styles.count}>{count}</div>
            {campaignExtraLabels && <div className={styles.label2}>{campaignExtraLabels.label2}</div>}
            <p className={styles.desc}>{desc}</p>
            <Button color='yellow' size='large' fullWidth onClick={onPress}>
                {t('Great')}!
            </Button>
        </>
    );
}

export default CampaignInfo;
