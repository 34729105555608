export enum Lang {
    en = 'en',
    uk = 'uk',
    nl = 'nl',
    de = 'de',
    fr = 'fr',
    it = 'it',
}

export enum Markets {
    nl = 'nl',
    be = 'be',
    de = 'de',
    at = 'at',
    ch = 'ch',
    fr = 'fr',
    it = 'it',
    // ir = 'ir',
    // gb = 'gb',
    // ua = 'ua',
}

export const langMap = {
    [Markets.de]: [Lang.de, Lang.en, Lang.uk, Lang.fr, Lang.it],
    [Markets.at]: [Lang.de, Lang.en, Lang.uk, Lang.fr, Lang.it],
    [Markets.nl]: [Lang.nl, Lang.en, Lang.uk, Lang.fr, Lang.it],
    [Markets.be]: [Lang.nl, Lang.en, Lang.uk, Lang.fr, Lang.it], //[Lang.nl, Lang.fr, Lang.en, Lang.uk],
    [Markets.ch]: [Lang.de, Lang.en, Lang.uk, Lang.fr, Lang.it], //[Lang.de, Lang.fr, Lang.it, Lang.en, Lang.uk],
    [Markets.fr]: [Lang.fr, Lang.en, Lang.uk],
    [Markets.it]: [Lang.it, Lang.en, Lang.uk],
    // [Markets.ua]: [Lang.uk, Lang.en],
    // [Markets.ir]: [Lang.en, Lang.uk],
    // [Markets.gb]: [Lang.en, Lang.uk],
}

export const shippingZones = {
    "Europe/Berlin": Markets.de,
    "Europe/Busingen": Markets.de,
    "Europe/Amsterdam": Markets.nl,
    "Europe/Brussels": Markets.be,
    "Europe/Vienna": Markets.at,
    "Europe/Zurich": Markets.ch,
    // "Europe/Paris": Markets.fr,
    // "Europe/London": Markets.gb,
    // "Europe/Rome": Markets.it,
}

export const SupportedMarkets = [Markets.de, Markets.nl, Markets.be, Markets.at, Markets.ch, Markets.it, Markets.fr];
