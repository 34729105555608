import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import ChildFaceIcon from '../../../../components/icons/ChildFace';
import BookClosedIcon from '../../../../components/icons/BookClosed';
import GlobeIcon from '../../../../components/icons/Globe';
import { useTranslation } from 'react-i18next';
import HomeWorkIcon from '../../../../components/icons/HomeWork';
import CalendarIcon from '../../../../components/icons/Calendar';
import PetsIcon from '../../../../components/icons/Pets';
import ScaleIcon from '../../../../components/icons/Scale';
import { Publisher } from '../../../../types/Publisher';
import Curtain from '../../../../components/common/Curtain';
import cn from 'classnames';
import CollectionsBookMarkIcon from '../../../../components/icons/CollectionsBookMark';
import PublisherInfo from '../../../Book/components/PublisherInfo';
import { TActivityBook, TCampaign, TListenTime, TPrintLength, TRecordableAudioBook } from '../../../../types/Sets';
import CampaignInfo from '../CampaignInfo';
import { getTextByLang } from '../../../../utils/langUtils';
import { LangContext } from '../../../../context/lang';
import { Lang } from '../../../../config/lang';
import { Author } from '../../../../types/IBook';
import ContributorsModal from '../../../Book/components/Contributors';
import PencilIcon from '../../../../components/icons/Pencil';
import BookPageIcon from '../../../../components/icons/BookPage';
import HeadPhoneIcon from '../../../../components/icons/HeadPhone';
import MicroPhoneIcon from '../../../../components/icons/MicroPhone';
import PdfIcon from '../../../../components/icons/Pdf';
import SquareFootIcon from "../../../../components/icons/SquareFoot";

type Props = {
    readingAge?: string;
    language?: string;
    printFormat?: string;
    genre?: string;
    weight?: string;
    includes?: string;
    publicationDate?: string;
    publishers?: Publisher[] | null;
    campaign?: TCampaign;
    contributors?: Author[];
    bookLangs: Lang[];
    printLength?: TPrintLength;
    listenTime?: TListenTime;
    recordableAudioBook?: TRecordableAudioBook;
    activityBook?: TActivityBook;
    ISBN?: string;
    dimensions?: string;
};

const Stats: FC<Props> = ({
    readingAge,
    language,
    printFormat,
    genre,
    weight,
    includes,
    publishers,
    publicationDate,
    contributors,
    campaign,
    bookLangs,
    printLength,
    listenTime,
    recordableAudioBook,
    activityBook,
    ISBN,
    dimensions,
}) => {
    const [openPublishers, setOpenPublishers] = useState<boolean>(false);
    const [openCampaign, setOpenCampaign] = useState<boolean>(false);
    const [openContributors, setOpenContributors] = useState<boolean>(false);
    const [openPrintLength, setOpenPrintLength] = useState<boolean>(false);
    const [openListenTime, setOpenListenTime] = useState<boolean>(false);
    const [openRecordableAudioBook, setOpenRecordableAudioBook] = useState<boolean>(false);
    const [openActivityBook, setOpenActivityBook] = useState<boolean>(false);

    const { t } = useTranslation('common');
    const { lang } = useContext(LangContext);

    const togglePublishers = useCallback(() => {
        setOpenPublishers(prev => !prev);
    }, []);

    const toggleCampaign = useCallback(() => {
        setOpenCampaign(prev => !prev);
    }, []);

    const toggleContributors = useCallback(() => {
        setOpenContributors(prev => !prev);
    }, []);

    const togglePrintLength = useCallback(() => {
        setOpenPrintLength(prev => !prev);
    }, []);

    const toggleListenTime = useCallback(() => {
        setOpenListenTime(prev => !prev);
    }, []);

    const toggleRecordableAudioBook = useCallback(() => {
        setOpenRecordableAudioBook(prev => !prev);
    }, []);

    const toggleActivityBook = useCallback(() => {
        setOpenActivityBook(prev => !prev);
    }, []);

    const publisherNames = useMemo(() => {
        if (!publishers) return null;
        return publishers.map(publisher => publisher.name).join(' & ');
    }, [publishers]);

    const campaignDescription = useMemo(() => {
        if (!campaign) return null;
        return (
            getTextByLang({
                texts: campaign?.descriptions,
                appLang: lang,
                fallbackLang: bookLangs[0],
            }) || null
        );
    }, [campaign, lang, bookLangs]);

    const convertMinutesToHoursAndMinutes = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let formattedTime = '';
        if (hours > 0) {
            formattedTime += `${hours}${t('hoursShort', 'h')} `;
        }
        if (remainingMinutes > 0 || hours === 0) {
            formattedTime += `${remainingMinutes} ${t('minutes')}`;
        }

        return formattedTime.trim();
    };

    return (
        <>
            <div className={styles.stats}>
                {readingAge && (
                    <div className={styles.stat}>
                        <label>{t('Reading age')}</label>
                        <div className={styles.icon}>
                            <ChildFaceIcon size={24} />
                        </div>
                        <strong>
                            {readingAge} {t('years')}
                        </strong>
                    </div>
                )}
                {includes && (
                    <div className={styles.stat}>
                        <label>{t('Includes')}</label>
                        <div className={styles.icon}>
                            <CollectionsBookMarkIcon size={24} />
                        </div>
                        <strong>{includes}</strong>
                    </div>
                )}
                {language && (
                    <div className={styles.stat}>
                        <label>{t('Language')}</label>
                        <div className={styles.icon}>
                            <GlobeIcon size={24} />
                        </div>
                        <strong>{language}</strong>
                    </div>
                )}
                {printFormat && (
                    <div className={styles.stat}>
                        <label>{t('Print format')}</label>
                        <div className={styles.icon}>
                            <BookClosedIcon size={24} />
                        </div>
                        <strong>{printFormat}</strong>
                    </div>
                )}
                {campaign && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={toggleCampaign}>
                        <label>{t('Campaign')}</label>
                        <div className={styles.icon}>
                            <HomeWorkIcon size={24} />
                        </div>
                        <strong>{t(campaign.name)}</strong>
                    </button>
                )}
                {!!publishers?.length && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={togglePublishers}>
                        <label>{t('Publisher')}</label>
                        <div className={styles.icon}>
                            <HomeWorkIcon size={24} />
                        </div>
                        <strong>{publisherNames}</strong>
                    </button>
                )}
                {contributors && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={toggleContributors}>
                        <label>{t('Contributors')}</label>
                        <div className={styles.icon}>
                            <PencilIcon size={24} />
                        </div>
                        <strong>
                            {contributors.length} {t('Contributors').toLowerCase()}
                        </strong>
                    </button>
                )}
                {genre && (
                    <div className={styles.stat}>
                        <label>{t('Genre')}</label>
                        <div className={styles.icon}>
                            <PetsIcon size={24} />
                        </div>
                        <strong>{genre}</strong>
                    </div>
                )}
                {publicationDate && (
                    <div className={styles.stat}>
                        <label>{t('Publication date')}</label>
                        <div className={styles.icon}>
                            <CalendarIcon size={24} />
                        </div>
                        <strong>{publicationDate}</strong>
                    </div>
                )}
                {printLength && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={togglePrintLength}>
                        <label>{t('Print length')}</label>
                        <div className={styles.icon}>
                            <BookPageIcon size={24} />
                        </div>
                        <strong>
                            <>
                                {printLength.total} {t('pages').toLowerCase()}
                            </>
                        </strong>
                    </button>
                )}
                {listenTime && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={togglePrintLength}>
                        <label>{t('Listen time (total)')}</label>
                        <div className={styles.icon}>
                            <HeadPhoneIcon size={24} />
                        </div>
                        <strong>
                            <>{convertMinutesToHoursAndMinutes(listenTime.total)}</>
                        </strong>
                    </button>
                )}
                {recordableAudioBook && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={toggleRecordableAudioBook}>
                        <label>{t('Recordable Audiobook')}</label>
                        <div className={styles.icon}>
                            <MicroPhoneIcon size={24} />
                        </div>
                        <strong>{t(recordableAudioBook.name)}</strong>
                    </button>
                )}
                {activityBook && (
                    <button type='button' className={cn(styles.stat, styles.selectable)} onClick={toggleActivityBook}>
                        <label>{t('Activity book(s)')}</label>
                        <div className={styles.icon}>
                            <PdfIcon size={24} />
                        </div>
                        <strong>{t(activityBook.name)}</strong>
                    </button>
                )}
                {weight && (
                    <div className={styles.stat}>
                        <label>{t('Weight')}</label>
                        <div className={styles.icon}>
                            <ScaleIcon size={24} />
                        </div>
                        <strong>{weight}</strong>
                    </div>
                )}
                {ISBN && (
                    <div className={styles.stat}>
                        <label>ISBN/EAN</label>
                        <div className={styles.icon}>
                            <ScaleIcon size={24} />
                        </div>
                        <strong>{ISBN}</strong>
                    </div>
                )}
                {dimensions && (
                    <div className={styles.stat}>
                        <label>{t('Dimensions')}</label>
                        <div className={styles.icon}>
                            <SquareFootIcon size={24} />
                        </div>
                        <strong>{dimensions} mm</strong>
                    </div>
                )}
            </div>
            {!!publishers?.length && (
                <Curtain open={openPublishers} onClose={togglePublishers}>
                    {publishers.map(publisher => (
                        <div key={publisher.id} className={styles.publisherBox}>
                            <PublisherInfo publisher={publisher} />
                        </div>
                    ))}
                </Curtain>
            )}
            {campaign && (
                <Curtain open={openCampaign} onClose={toggleCampaign}>
                    <CampaignInfo
                        name={campaign.name}
                        count={campaign.count}
                        desc={campaignDescription || ''}
                        onPress={toggleCampaign}
                    />
                </Curtain>
            )}
            {contributors && (
                <ContributorsModal
                    open={openContributors}
                    onClose={toggleContributors}
                    contributors={contributors}
                    bookLang={bookLangs[0]}
                />
            )}
            {printLength && (
                <Curtain open={openPrintLength} onClose={togglePrintLength} title={t('Print length')}>
                    {getTextByLang({
                        texts: printLength?.description,
                        appLang: lang,
                        fallbackLang: bookLangs[0],
                    })}
                </Curtain>
            )}
            {listenTime && (
                <Curtain open={openListenTime} onClose={toggleListenTime} title={t('Listen time (total)')}>
                    {getTextByLang({
                        texts: listenTime?.description,
                        appLang: lang,
                        fallbackLang: bookLangs[0],
                    })}
                </Curtain>
            )}
            {recordableAudioBook && (
                <Curtain open={openRecordableAudioBook} onClose={toggleRecordableAudioBook} title={t('Recordable Audiobook')}>
                    {getTextByLang({
                        texts: recordableAudioBook?.description,
                        appLang: lang,
                        fallbackLang: bookLangs[0],
                    })}
                </Curtain>
            )}
            {activityBook && (
                <Curtain open={openActivityBook} onClose={toggleActivityBook} title={t('Activity book(s)')}>
                    {getTextByLang({
                        texts: activityBook?.description,
                        appLang: lang,
                        fallbackLang: bookLangs[0],
                    })}
                </Curtain>
            )}
        </>
    );
};

export default Stats;
