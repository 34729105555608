import React, { FC, ReactNode, useCallback, useState } from 'react';
import Button from '../../../../components/common/Button';
import Curtain from '../../../../components/common/Curtain';
import { BookFormat } from '../../../../types/IBook';
import styles from './styles.module.scss';
import HeadPhoneThinIcon from '../../../../components/icons/HeadPhoneThin';
import MicroPhoneThinIcon from '../../../../components/icons/MicroPhoneThin';
import RemoteRecordingIcon from '../../../../components/icons/RemoteRecording';
import CheckMark from '../../../../components/icons/CheckMark';
import { useQrCode } from '../../../../queries/useQrCode';
import {
    BOOK_ALREADY_ADDED,
    BOOK_NOT_FOUND,
    useAddBookToUserLibrary,
    USER_NOT_AUTHORIZED,
} from '../../../../commands/useAddBookToUserLibrary';
import qrCodeStorage from '../../../../storage/qr-code';
import routes from '../../../../config/routes';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
    formats: BookFormat[];
    buttonLabel?: string;
    small?: boolean;
    customButton?: ReactNode;
};

const AddToLibrary: FC<Props> = ({ buttonLabel, small, formats, customButton }) => {
    const { t } = useTranslation('book');
    const [open, setOpen] = useState(false);

    const { data: qrCode } = useQrCode();
    const { mutateAsync: addBookToLibrary } = useAddBookToUserLibrary();

    const navigate = useNavigate();

    const toggleOpen = useCallback(() => setOpen(prev => !prev), []);

    const getTitle = useCallback(
        (format: BookFormat) => {
            switch (format) {
                case BookFormat.recording:
                    return t('recording');
                case BookFormat.audio:
                    return t('audiobook');
                case BookFormat.ebook:
                    return t('remoteRecording');
                default:
                    return '';
            }
        },
        [t],
    );

    const getIcon = useCallback((format: BookFormat) => {
        switch (format) {
            case BookFormat.audio:
                return <HeadPhoneThinIcon size={48} />;
            case BookFormat.recording:
                return <MicroPhoneThinIcon size={48} />;
            case BookFormat.ebook:
                return <RemoteRecordingIcon size={48} />;
            default:
                return null;
        }
    }, []);

    const onAddToLibrary = useCallback(async () => {
        try {
            const qrCodeData = qrCodeStorage.get();
            if (qrCodeData) qrCodeStorage.set({ ...qrCodeData, canAddToLibrary: true });
            await addBookToLibrary({ qrCode });
            navigate(routes.library);
        } catch (error: any) {
            let message = error.message === BOOK_ALREADY_ADDED ? t('bookAlreadyAdded') : null;
            message = error.message === BOOK_NOT_FOUND ? t('bookNotFound') : message;
            if (message) {
                toast.info(message);
            }
            if (error.message === USER_NOT_AUTHORIZED) {
                navigate(routes.accountSignUp);
            }
        } finally {
            // toggleOpen();
        }
    }, [qrCode, navigate, addBookToLibrary, t]);

    return (
        <>
            {customButton ? (
                <button type='button' className={styles.customButton} onClick={onAddToLibrary}>{customButton}</button>
            ) : (
                <Button fullWidth onClick={onAddToLibrary} size={small ? 'small' : 'medium'} color='yellow'>
                    {buttonLabel || t('addToLibrary')}
                </Button>
            )}

            <Curtain open={open} onClose={toggleOpen}>
                <p className={styles.txt}>{t('selectFormatsToBuy')}</p>
                {formats?.map(format => (
                    <div className={styles.box} key={format}>
                        <div className={styles.iconBox}>{getIcon(format)}</div>
                        <div className={styles.info}>
                            <strong>{getTitle(format)}</strong>
                            <span>{t('free')}</span>
                        </div>
                        <div className={styles.checkBox}>
                            <span className={styles.checkMark}>
                                <CheckMark size={16} color='white' />
                            </span>
                        </div>
                    </div>
                ))}
                <Button className={styles.btn} fullWidth onClick={onAddToLibrary} textUpperCase>
                    {t('byAllForFree')}
                </Button>
            </Curtain>
        </>
    );
};

export default AddToLibrary;
