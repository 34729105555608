import React, { FunctionComponent, Suspense, lazy, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import routes, { routeRecordID } from "config/routes";
import AuthGuard from './guards/AuthGuard';
import AnonGuard from './guards/AnonGuard';
import Loader from './components/Loader';
import { initDatalog } from './utils/dataLog';
import AuthWithLink from './pages/SignUp/AuthWithLink';
import { RedirectToNotFoundPage } from "./components/Redirect";
import Set from "./pages/Set";

const HomePage = lazy(() => import('./pages/Home'));

const Account = lazy(() => import('./pages/Account/index'));
const ManageRecords = lazy(() => import('./pages/Account/views/ManageRecords'));
const AccountSettings = lazy(() => import('./pages/Account/views/Settings'));

const SignUpPage = lazy(() => import('./pages/SignUp'));
const LibraryBooksView = lazy(() => import('./pages/Library/views/Books'));
const BookScanPage = lazy(() => import('./pages/Scan'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));
const Book = lazy(() => import('./pages/Book'));
const Library = lazy(() => import('./pages/Library'));

// Record components
const Record = lazy(() => import('./pages/Record'));
const RemoteRecordPage = lazy(() => import('./pages/RemoteRecord'));
const RemoteIntroView = lazy(() => import('./pages/RemoteRecord/views/Intro'));
const RemoteCompleteView = lazy(() => import('./pages/RemoteRecord/views/Complete'));
const RecordingView = lazy(() => import('./pages/Record/views/Recording'));
const Review = lazy(() => import('./pages/Record/views/Review'));
const Feedback = lazy(() => import('./pages/Record/views/Feedback'));
// const BookIntro = lazy(() => import('./pages/Record/views/BookIntro'));
const Contributor = lazy(() => import('./pages/Contributor'));
const Publisher = lazy(() => import('./pages/Publisher'));
const Products = lazy(() => import('./pages/Products'));
const Product = lazy(() => import('./pages/Product'));

const RoutesRoot: FunctionComponent = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        initDatalog();
    }, []);

    useEffect(() => {
        window?.analytics?.page(pathname);
    }, [pathname]);
    return (
        <Routes>
            <Route
                path={routes.home}
                element={
                    <Suspense fallback={<Loader />}>
                        <HomePage />
                    </Suspense>
                }
            />
            {/*<Route*/}
            {/*    path={`${routes.account.root}/${routes.account.manageRecords}`}*/}
            {/*    element={*/}
            {/*        <AuthGuard>*/}
            {/*            <Suspense fallback={<Loader />}>*/}
            {/*                <ManageRecords />*/}
            {/*            </Suspense>*/}
            {/*        </AuthGuard>*/}
            {/*    }*/}
            {/*/>*/}
            {/*<Route*/}
            {/*    path={`${routes.account.root}/${routes.account.settings}`}*/}
            {/*    element={*/}
            {/*        <AuthGuard>*/}
            {/*            <Suspense fallback={<Loader />}>*/}
            {/*                <AccountSettings />*/}
            {/*            </Suspense>*/}
            {/*        </AuthGuard>*/}
            {/*    }*/}
            {/*/>*/}
            <Route
                path={routes.account.root}
                element={
                    <AuthGuard>
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                    </AuthGuard>
                }
            >
                <Route index element={<Account />} />
                <Route path={routes.account.manageRecords} element={<ManageRecords />} />
                <Route path={routes.account.settings} element={<AccountSettings />} />
            </Route>

            <Route path={routes.accountSignUp} element={<Outlet />}>
                <Route
                    index
                    element={
                        <AnonGuard>
                            <Suspense fallback={<Loader />}>
                                <SignUpPage />
                            </Suspense>
                        </AnonGuard>
                    }
                />
                <Route
                    path='link'
                    element={
                        <AnonGuard>
                            <Suspense fallback={<Loader />}>
                                <AuthWithLink />
                            </Suspense>
                        </AnonGuard>
                    }
                />
            </Route>
            <Route
                path={routes.library}
                element={
                    <AuthGuard>
                        <Suspense fallback={<Loader />}>
                            <Library />
                        </Suspense>
                    </AuthGuard>
                }
            >
                <Route index element={<Navigate to='books' replace />} />
                <Route path='books' element={<LibraryBooksView />} />
            </Route>

            <Route
                path={routes.book}
                element={
                    <Suspense fallback={<Loader />}>
                        <Book />
                    </Suspense>
                }
            />
            <Route
                path={routes.set}
                element={
                    <Suspense fallback={<Loader />}>
                        <Set />
                    </Suspense>
                }
            />
            <Route
                path={routes.bookScan}
                element={
                    <Suspense fallback={<Loader />}>
                        <BookScanPage />
                    </Suspense>
                }
            />

            {/* Record Routes - we use relative routes nested of Nested to avoid looping in nested routes when use navigate(-1), so we can use back buttons to go back to page from where enter recording */}
            <Route path={`${routes.record.root}/${routeRecordID}?`} element={<AuthGuard><Suspense fallback={<Loader />}><Record>{null}</Record></Suspense></AuthGuard>} />
            <Route path={`${routes.record.root}/${routeRecordID}/${routes.record.recording}`} element={<AuthGuard><Suspense fallback={<Loader />}><Record><RecordingView /></Record></Suspense></AuthGuard>} />
            <Route path={`${routes.record.root}/${routeRecordID}/${routes.record.review}`} element={<AuthGuard><Suspense fallback={<Loader />}><Record><Review /></Record></Suspense></AuthGuard>} />
            <Route path={`${routes.record.root}/${routeRecordID}/${routes.record.feedback}`} element={<AuthGuard><Suspense fallback={<Loader />}><Record><Feedback /></Record></Suspense></AuthGuard>} />

            <Route path={`${routes.remoteRecord.root}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage>{null}</RemoteRecordPage></Suspense></AuthGuard>} />
            <Route path={`${routes.remoteRecord.root}/${routeRecordID}/${routes.remoteRecord.intro}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage><RemoteIntroView /></RemoteRecordPage></Suspense></AuthGuard>} />
            <Route path={`${routes.remoteRecord.root}/${routeRecordID}/${routes.record.recording}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage><RecordingView /></RemoteRecordPage></Suspense></AuthGuard>} />
            <Route path={`${routes.remoteRecord.root}/${routeRecordID}/${routes.record.review}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage><Review /></RemoteRecordPage></Suspense></AuthGuard>} />
            <Route path={`${routes.remoteRecord.root}/${routeRecordID}/${routes.record.feedback}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage><Feedback /></RemoteRecordPage></Suspense></AuthGuard>} />
            <Route path={`${routes.remoteRecord.root}/${routeRecordID}/${routes.remoteRecord.complete}`} element={<AuthGuard acceptAnonymous><Suspense fallback={<Loader />}><RemoteRecordPage><RemoteCompleteView /></RemoteRecordPage></Suspense></AuthGuard>} />

            <Route
                path={routes.contributor}
                element={
                    <Suspense fallback={<Loader />}>
                        <Contributor />
                    </Suspense>
                }
            />
            <Route
                path={routes.publisher}
                element={
                    <Suspense fallback={<Loader />}>
                        <Publisher />
                    </Suspense>
                }
            />

            <Route
                path={routes.products.root}
                element={
                    <Suspense fallback={<Loader />}>
                        <Products />
                    </Suspense>
                }
            />

            <Route
                path={routes.products.product}
                element={
                    <Suspense fallback={<Loader />}>
                        <Product />
                    </Suspense>
                }
            />

            <Route
                path={routes.notFound}
                element={
                    <Suspense fallback={<Loader />}>
                        <NotFoundPage />
                    </Suspense>
                }
            />
            <Route path='*' element={<RedirectToNotFoundPage />} />
        </Routes>
    );
};

export default RoutesRoot;
