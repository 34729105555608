import { SvgFactory } from '../../Svg';

const HomeWorkIcon = SvgFactory(
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='black'>
        <path d='M14.16 10.4L9.16 6.83C8.46 6.33 7.53 6.33 6.84 6.83L1.84 10.4C1.31 10.78 1 11.38 1 12.03V20C1 20.55 1.45 21 2 21H6V15H10V21H14C14.55 21 15 20.55 15 20V12.03C15 11.38 14.69 10.78 14.16 10.4Z' />
        <path d='M21.03 3H11.97C10.88 3 10 3.88 10 4.97L10.09 5.06C10.17 5.11 10.25 5.15 10.33 5.2L15.33 8.77C16.09 9.31 16.63 10.11 16.87 11H19V13H17V15H19V17H17V21H21.03C22.12 21 23 20.12 23 19.03V4.97C23 3.88 22.12 3 21.03 3ZM19 9H17V7H19V9Z' />
    </svg>,
);

export default HomeWorkIcon;
