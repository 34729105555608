import React, { FC, useMemo } from "react";
import styles from './styles.module.scss';
import { Lang } from "../../../../../config/lang";
import Typography from "../../../Typography";
import { TypographySize, TypographyTag } from "../../../Typography/types";
import RadioButtonGroup from "../../../RadioButtonGroup";
import { Link } from "react-router-dom";
import routes, { routeBookID } from "../../../../../config/routes";
import Button from "../../../Button";
import MicroPhoneIcon from "../../../../icons/MicroPhone";
import RecordVoiceIcon from "../../../../icons/RecordVoice";
import { useTranslation } from "react-i18next";
import { flags } from "../../../../../config/flags";

type Props = {
    bookId: string;
    bookLangs: Lang[];
    recordLang: Lang;
    onRemoteRecPress: () => void;
    handleLangChange: (lang: string) => void;
};

const RecordOptions: FC<Props> = ({ bookId, bookLangs, recordLang, onRemoteRecPress, handleLangChange }) => {
    const { t } = useTranslation('common');

    const langOptions = useMemo(() => {
        if (!bookLangs?.length || bookLangs.length === 1) return [];

        return [
            {
                icon: <img width={20} height={20} src={flags[bookLangs[0]]} alt={bookLangs[0]} loading='lazy' />,
                label: t(`langs.${bookLangs[0]}`),
                value: bookLangs[0],
            },
            {
                icon: <img width={20} height={20} src={flags[bookLangs[1]]} alt={bookLangs[1]} loading='lazy' />,
                label: t(`langs.${bookLangs[1]}`),
                value: bookLangs[1],
            },
        ];
    }, [t, bookLangs]);

    return (
        <>
            <div className={styles.top}>
                <Typography size={TypographySize.L} tagName={TypographyTag.h5}>
                    {t('recordOptionsModalTitle')}: <br />
                    {t('recordOptionsModalTitle2')}!
                </Typography>
                <Typography size={TypographySize.S}>{t('recordOptionsModalDesc')}</Typography>
            </div>
            {bookLangs?.length > 1 && (
                <div className={styles.langs}>
                    <Typography size={TypographySize.M} tagName={TypographyTag.p}>
                        {t('selectLanguage')}
                    </Typography>
                    <RadioButtonGroup value={recordLang} options={langOptions} onChange={handleLangChange} />
                </div>
            )}
            <Typography size={TypographySize.M} tagName={TypographyTag.p} className={styles.subTitle}>
                {t('recordOptionsModalSubTitle')}
            </Typography>
            <div className={styles.btnBox}>
                <Link to={`${routes.record.root.replace(routeBookID, bookId)}?lang=${recordLang}`}>
                    <Button color="red" fullWidth textUpperCase icon={<MicroPhoneIcon />}>
                        {t('withOwnVoice')}
                    </Button>
                </Link>
            </div>
            <div className={styles.btnBox}>
                <Button color="blue" textUpperCase ghost icon={<RecordVoiceIcon />} size='small' onClick={onRemoteRecPress}>
                    {t('inviteToRecord')}
                </Button>
            </div>
            <Typography size={TypographySize.XS} className={styles.hint}>
                {t('recordOptionsModalHint')}
            </Typography>
        </>
    );
}

export default RecordOptions;
