import React, { FC } from 'react';
import { Publisher } from '../../../../types/Publisher';
import Typography from '../../../../components/common/Typography';
import { TypographySize, TypographyTag } from '../../../../components/common/Typography/types';
import VerifiedIcon from '../../../../components/icons/Verified';
import Button from '../../../../components/common/Button';
import { getIcon, getLabel } from '../../../Contributor/utils';
import { getSizedImage, ImageSizesValues } from '../../../../utils/getSizedImage';
import { useTranslation } from 'react-i18next';
import LocationPinIcon from '../../../../components/icons/LocationPin';
import { Link } from 'react-router-dom';
import routes, { routeID } from '../../../../config/routes';

import styles from './styles.module.scss';

type Props = {
    publisher: Publisher;
};

const PublisherInfo: FC<Props> = ({ publisher }) => {
    const sizeImage = publisher?.avatar?.src ? getSizedImage(publisher?.avatar?.src, ImageSizesValues['140x140']) : null;
    const { t } = useTranslation('book');

    return (
        <div>
            <div className={styles.wrap}>
                {publisher?.avatar?.src && (
                    <picture className={styles.img}>
                        {sizeImage?.webp && <source srcSet={sizeImage.webp} type='image/webp' />}
                        <img src={sizeImage?.jpg || sizeImage?.original} alt='' loading='eager' />
                    </picture>
                )}
                <div className={styles.info}>
                    <Typography size={TypographySize.L} bold tagName={TypographyTag.h4}>
                        {publisher?.name}&nbsp;
                        <VerifiedIcon size={24} />
                    </Typography>
                    <div className={styles.types}>
                        {publisher?.types?.map(type => (
                            <Button type='button' key={type} size='xs' color='gray' icon={getIcon(type)}>
                                <em>{getLabel(type, t)}</em>
                            </Button>
                        ))}
                    </div>
                    {publisher?.location && <div className={styles.address}><Typography size={TypographySize.S} bold><LocationPinIcon size={16} />&nbsp;{publisher.location}</Typography></div>}
                </div>
            </div>
            <div className={styles.btnBox}>
                <Link to={routes.publisher.replace(routeID, publisher.id)}>
                    <Button type='button' color='white' size='small' fullWidth>
                        {t('learnMore')}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default PublisherInfo;
