import { SvgFactory } from '../../Svg';
import React from 'react';

const LocationPinIcon = SvgFactory(
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='black'>
        <path d='M7.99984 1.44086C5.19984 1.44086 2.6665 3.58752 2.6665 6.90752C2.6665 9.02752 4.29984 11.5209 7.55984 14.3942C7.81317 14.6142 8.19317 14.6142 8.4465 14.3942C11.6998 11.5209 13.3332 9.02752 13.3332 6.90752C13.3332 3.58752 10.7998 1.44086 7.99984 1.44086ZM7.99984 8.10752C7.2665 8.10752 6.6665 7.50752 6.6665 6.77419C6.6665 6.04086 7.2665 5.44086 7.99984 5.44086C8.73317 5.44086 9.33317 6.04086 9.33317 6.77419C9.33317 7.50752 8.73317 8.10752 7.99984 8.10752Z' />
    </svg>,
);

export default LocationPinIcon;
