import { Markets } from "../../../../config/lang";

export const getMarketKey = (market: Markets) => {
    switch (market) {
        case Markets.de:
            return 'germany';
        case Markets.nl:
            return 'netherlands';
        case Markets.ch:
            return 'switzerland';
        case Markets.at:
            return 'austria';
        case Markets.be:
            return 'belgium';
        case Markets.fr:
            return 'france';
        case Markets.it:
            return 'italy';
        default:
            return '';
    }
};

export const getMarketCurrencyKey = (market: Markets) => {
    switch (market) {
        case Markets.de:
            return 'euro';
        case Markets.nl:
            return 'euro';
        case Markets.ch:
            return 'swissFranc';
        case Markets.at:
            return 'euro';
        case Markets.be:
            return 'euro';
        case Markets.fr:
            return 'euro';
        case Markets.it:
            return 'euro';
        default:
            return '';
    }
};

export const getMarketCurrencySymbol = (market: Markets) => {
    switch (market) {
        case Markets.de:
            return '€';
        case Markets.nl:
            return '€';
        case Markets.ch:
            return '₣';
        case Markets.at:
            return '€';
        case Markets.be:
            return '€';
        default:
            return '';
    }
};
