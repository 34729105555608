import React, { FC } from 'react';

const LogoIcon: FC = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
        <path
            d='M23.8956 8.78463L19.6364 1.81603C19.3066 1.27947 18.8512 0.834709 18.3112 0.521628C17.7711 0.208547 17.1632 0.0369377 16.5421 0.0221898C15.9209 0.00744194 15.3058 0.150017 14.752 0.437128C14.1983 0.724239 13.7231 1.14691 13.3691 1.66723L11.9879 3.65737L12.0305 3.73797L19.3321 14.4699C19.4957 14.7048 19.5935 14.9807 19.6149 15.2679C19.6364 15.5552 19.5807 15.843 19.4538 16.1004L23.8956 9.47901C23.9637 9.37649 24 9.25557 24 9.13182C24 9.00807 23.9637 8.88716 23.8956 8.78463Z'
            fill='#FFDE13'
        />
        <path
            d='M11.9572 21.7176L3.82801 9.60938C3.7637 9.50544 3.72956 9.38507 3.72956 9.26219C3.72956 9.13931 3.7637 9.01894 3.82801 8.915L8.17249 1.69841C8.18718 1.67457 8.20344 1.65179 8.22117 1.63021C8.21853 1.61795 8.21853 1.60526 8.22117 1.59301C8.23952 1.52399 8.23952 1.45123 8.22117 1.38221L8.22136 1.33876C8.22336 1.32437 8.22336 1.30976 8.22136 1.29537C8.22311 1.28303 8.22291 1.27055 8.22117 1.25822L8.18466 1.22102C8.10213 1.14907 8.00047 1.10375 7.89259 1.09082C7.43739 0.983428 6.96057 1.02464 6.52962 1.20862C6.20109 1.36499 5.92572 1.61752 5.73861 1.934L1.66795 8.8034C1.59497 8.92125 1.55624 9.05775 1.55624 9.19709C1.55624 9.33644 1.59497 9.47293 1.66795 9.59078L10.8619 23.2304L13.0037 23.311L11.9572 21.7176Z'
            fill='#FFDE13'
        />
        <path
            d='M19.3442 14.4576L12.0305 3.73797L10.637 1.66738L10.5579 1.56198V1.52478C10.1904 1.02739 9.70937 0.628886 9.15649 0.363876C8.60361 0.0988675 7.9956 -0.0246416 7.38525 0.00407091C6.7749 0.0327834 6.18065 0.21285 5.65408 0.528638C5.12752 0.844427 4.68456 1.28639 4.36365 1.81617L0.104357 8.78478C0.0363452 8.8873 0 9.00822 0 9.13197C0 9.25572 0.0363452 9.37663 0.104357 9.47916L9.58432 23.6148C9.63998 23.6992 9.71515 23.7683 9.80322 23.8161C9.89129 23.8639 9.98955 23.8889 10.0893 23.8889C10.1891 23.8889 10.2874 23.8639 10.3755 23.8161C10.4635 23.7683 10.5387 23.6992 10.5944 23.6148L10.8864 23.218L1.69855 9.57836C1.62694 9.46 1.58902 9.3237 1.58902 9.18467C1.58902 9.04563 1.62694 8.90933 1.69855 8.79098L5.71445 1.94637C5.90317 1.62897 6.18077 1.37634 6.51154 1.22099C6.94652 1.02792 7.43136 0.984389 7.89277 1.09699C7.98534 1.11687 8.07255 1.15707 8.14833 1.21479C8.19947 1.26243 8.23675 1.32351 8.25607 1.39131C8.27539 1.45912 8.276 1.53104 8.25785 1.59918V1.63638L8.21526 1.70458L3.82211 8.92117C3.75794 9.02632 3.72394 9.14765 3.72394 9.27146C3.72394 9.39527 3.75794 9.51661 3.82211 9.62175L11.9572 21.7176L13.0222 23.3048L13.2899 23.7016C13.3441 23.7927 13.4205 23.868 13.5116 23.9203C13.6027 23.9725 13.7056 24 13.8101 24C13.9147 24 14.0175 23.9725 14.1087 23.9203C14.1998 23.868 14.2761 23.7927 14.3304 23.7016L19.3503 16.2618C19.5291 15.996 19.6244 15.681 19.6233 15.3587C19.6222 15.0365 19.5248 14.7221 19.3442 14.4576Z'
            fill='#5397EE'
        />
    </svg>
);

export default LogoIcon;
