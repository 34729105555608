import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Author, AuthorType } from '../../../../types/IBook';
import Typography from '../../../../components/common/Typography';
import { useTranslation } from 'react-i18next';
import { TypographySize, TypographyTag } from '../../../../components/common/Typography/types';
import styles from './styles.module.scss';
import { getSizedImage, ImageSizesValues } from '../../../../utils/getSizedImage';
import { getAuthorName } from '../../../../utils/langUtils';
import { LangContext } from '../../../../context/lang';
import { Lang } from '../../../../config/lang';
import Button from '../../../../components/common/Button';
import { getIcon, getLabel } from '../../../Contributor/utils';
import ShowMoreText from 'react-show-more-text';
import stylesCommon from '../../../../styles/common.module.scss';
import HeartIcon from '../../../../components/icons/Heart';
import { AuthContext } from '../../../../context/auth';
import HeartOutLineIcon from '../../../../components/icons/HeartOutline';
import VerifiedIcon from '../../../../components/icons/Verified';
import routes, { routeID } from '../../../../config/routes';
import { useMutation, useQueryClient } from 'react-query';
import { getAuthorsQueryKey, requestUpdateAuthor } from '../../../../services/author';
import { requestUpdateUser } from '../../../../services/user';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AvatarSize } from '../../../../components/common/Avatar/types';
import Avatar from '../../../../components/common/Avatar';

const sortPriorities = [AuthorType.author, AuthorType.illustrator, AuthorType.narrator, AuthorType.translator, AuthorType.editor];

type Props = {
    contributors: Author[];
};

const ContributorsCards: FC<Props> = ({ contributors }) => {
    const { t } = useTranslation('book');
    const { lang } = useContext(LangContext);
    const { user, isAuthenticated } = useContext(AuthContext);

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { mutate: updateAuthor, isLoading: isUpdatingAuthor } = useMutation(requestUpdateAuthor, {
        onSuccess: () => {
            queryClient.invalidateQueries([getAuthorsQueryKey]);
        },
    });

    const { mutate: updateUser } = useMutation(requestUpdateUser);

    const handleFollow = useCallback(
        (contributor: Author, followed: boolean) => {
            if (!isAuthenticated) {
                navigate(routes.accountSignUp);
                return;
            }

            if (!contributor || !user) return;

            if (followed) {
                // unfollow
                const userIds = contributor?.followers.userIDs?.filter(id => id !== user.id);
                updateAuthor({
                    id: contributor.id,
                    data: {
                        followers: { ...contributor.followers, userIDs: userIds },
                    },
                });
                updateUser({
                    id: user.id,
                    data: {
                        following: {
                            ...user.following,
                            authorIDs: user?.following.authorIDs.filter(id => id !== contributor.id),
                        },
                    },
                });
            } else {
                // follow
                const userIds = [...(contributor?.followers.userIDs || []), user?.id];
                updateAuthor({
                    id: contributor.id,
                    data: {
                        followers: { ...contributor.followers, userIDs: userIds },
                    },
                });
                updateUser({
                    id: user.id,
                    data: {
                        following: {
                            ...user.following,
                            authorIDs: [...(user?.following.authorIDs || []), contributor.id],
                        },
                    },
                });
            }
        },
        [isAuthenticated, navigate, updateAuthor, updateUser, user],
    );

    const contributorList = useMemo(() => {
        const sortedData = [...contributors];

        // Function to find the highest priority of a given types array
        const findHighestPriority = (types: AuthorType[]) => {
            const priorityRanks = types.map(type => sortPriorities.indexOf(type));
            return Math.min(...priorityRanks);
        };

        sortedData.sort((a, b) => {
            const highestPriorityA = findHighestPriority(a.types);
            const highestPriorityB = findHighestPriority(b.types);

            // Compare by the highest priority type
            return highestPriorityA - highestPriorityB;
        });

        return sortedData;
    }, [contributors]);

    return (
        <div className={styles.contributorsCards}>
            <Typography size={TypographySize.L} tagName={TypographyTag.h3}>
                {t('contributors')}
            </Typography>
            <Typography size={TypographySize.S}>{t('contributorsIntro')}</Typography>

            {contributorList?.map(contributor => {
                const { id, avatar, description } = contributor;
                const sizeImage = getSizedImage(avatar?.src, ImageSizesValues['140x140']);
                const contributorName = getAuthorName({ author: contributor, appLang: lang, fallbackLang: Lang.en });

                const followed = isAuthenticated && !!contributor?.followers.userIDs?.includes(user?.id || '');

                return (
                    <div className={styles.card} key={id}>
                        <header>
                            {avatar?.src && (
                                <div className={styles.img}>
                                    <Link to={routes.contributor.replace(routeID, id)}>
                                        <Avatar
                                            avatar={sizeImage?.jpg || sizeImage?.webp || undefined}
                                            userName={contributorName}
                                            size={AvatarSize.XL}
                                        />
                                    </Link>
                                </div>
                            )}
                            <div className={styles.titleBox}>
                                <Link to={routes.contributor.replace(routeID, id)}>
                                    <Typography size={TypographySize.L} tagName={TypographyTag.h4}>
                                        {contributorName}&nbsp;
                                        <VerifiedIcon size={20} />
                                    </Typography>
                                </Link>
                                <div className={styles.types}>
                                    {contributor.types?.map(type => (
                                        <Button type='button' key={type} size='xs' color='gray' icon={getIcon(type)}>
                                            <em>{getLabel(type, t)}</em>
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </header>
                        {description && (
                            <div className={styles.txt}>
                                <ShowMoreText
                                    more={t('more', { ns: 'common' })}
                                    less={t('showLess', { ns: 'common' })}
                                    className={stylesCommon.moreLessText}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </ShowMoreText>
                            </div>
                        )}
                        <div className={styles.btn}>
                            <Button
                                color='gray'
                                ghost
                                icon={followed ? <HeartIcon /> : <HeartOutLineIcon />}
                                fullWidth
                                onClick={() => handleFollow(contributor, followed)}
                                isLoading={isUpdatingAuthor}
                            >
                                {followed ? t('following') : t('follow')}
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ContributorsCards;
