import { Contributor } from "./IBook";
import { ICustomMap } from "./ICustomMap";
import { Lang } from "../config/lang";
import { TDescription } from "./Works";

export type TSetPrice = {
    price: number;
    salePrice: number;
    shopLink: string;
}

export type TKeyWord = {
    lang: Lang;
    text: string[];
};

export type TCampaign = {
    count: number;
    max_count: number;
    name: string;
    descriptions: TDescription[];
};

export type TPrintLength = {
    total: number;
    description: TDescription[];
};

export type TListenTime = {
    total: number;
    description: TDescription[];
};

export type TRecordableAudioBook = {
    description: TDescription[];
    name: string;
}

export type TActivityBook = {
    description: TDescription[];
    name: string;
}

export type TSet = {
    cover: {
        src: string;
    };
    title: string;
    subTitle?: string;
    formatsInfo?: string;
    price: ICustomMap<TSetPrice>;
    id: string;
    contributors: Contributor[];
    shippingCountry: string[];
    bundles: string[];
    description?: TDescription[];
    keyWords?: TKeyWord[];
    inLang: Lang[];
    categoryTag: string;
    inStock: boolean;
    age: string;
    fulfilled?: string;
    printFormat?: string;
    publicationDate?: string;
    genre?: string;
    weight?: string;
    setType?: string;
    publisher?: string[];
    ISBN?: string;
    dimensions?: string;
    aggregateRating?: {
        ratingValue: number;
        ratingCount: number;
    };
    campaign:TCampaign;
    printLength?: TPrintLength;
    listenTime?: TListenTime;
    recordableAudioBook?: TRecordableAudioBook;
    activityBook?: TActivityBook;

}

export enum ECampaignType {
    'TU' = "Tales of Ukraine",
    'DG' = 'Donation Gift',
    'BSGB' = 'Buy One Set & Gift One Book',
}
