import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { getSizedImage, ImageSizesValues } from '../../../../utils/getSizedImage';
import HeartOutline from '../../../../components/icons/HeartOutline';
import ShareIcon from '../../../../components/icons/ShareIcon';
import { handleShareLink } from '../../../../utils/copyShare';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../context/auth';
import HeartIcon from '../../../../components/icons/Heart';
import { useNavigate } from 'react-router';
import routes from '../../../../config/routes';
import { useMutation, useQueryClient } from 'react-query';
import { requestUpdateUser } from '../../../../services/user';
import { QueryKey } from '../../../../queries';
import { TCampaign } from '../../../../types/Sets';
import Curtain from '../../../../components/common/Curtain';
import CampaignInfo from '../CampaignInfo';
import { getTextByLang } from '../../../../utils/langUtils';
import { Lang } from '../../../../config/lang';
import { LangContext } from '../../../../context/lang';

type Props = {
    prodId: string;
    cover: string;
    title: string;
    campaign?: TCampaign;
};

const Cover: FC<Props> = ({ prodId, cover, title, campaign }) => {
    const sizedCover = getSizedImage(cover, ImageSizesValues['320x320']);
    const { t } = useTranslation('common');
    const { user, isAuthenticated } = useContext(AuthContext);
    const { lang } = useContext(LangContext);
    const [openCampaign, setOpenCampaign] = useState<boolean>(false);

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { mutate: updateUser } = useMutation(requestUpdateUser, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.GET_USER, user?.id]);
        },
    });

    const { wishList } = user || {};

    const isInWishList = wishList?.find(({ id }) => id === prodId);

    const handleShare = useCallback(() => {
        const url = window.location.href;
        handleShareLink({
            url,
            title,
            onDone: () => {
                toast.success(t('copiedToClipboard'));
            },
        });
    }, [t, title]);

    const toggleAddToWishlist = useCallback(() => {
        if (isAuthenticated) {
            updateUser({
                id: user?.id || '',
                data: {
                    wishList: isInWishList
                        ? wishList?.filter(({ id }) => id !== prodId)
                        : [
                              ...(wishList || []),
                              {
                                  id: prodId,
                                  type: 'smart_set',
                              },
                          ],
                },
            });
        } else {
            navigate(routes.accountSignUp);
        }
    }, [isAuthenticated, navigate, prodId, updateUser, user, wishList, isInWishList]);

    const toggleOpenCampaign = useCallback(() => {
        setOpenCampaign(prev => !prev);
    }, []);

    const campaignDescription = useMemo(() => {
        if (!campaign) return null;
        return (
            getTextByLang({
                texts: campaign?.descriptions,
                appLang: lang,
                fallbackLang: Lang.en,
            }) || null
        );
    }, [campaign, lang]);

    return (
        <>
            <div className={styles.cover}>
                <button className={styles.favoriteBtn} type='button' onClick={toggleAddToWishlist}>
                    {isInWishList ? <HeartIcon size={24} /> : <HeartOutline size={24} />}
                </button>
                <picture>
                    {sizedCover?.webp && <source srcSet={sizedCover.webp} type='image/webp' />}
                    <img src={sizedCover?.jpg || cover} alt={title} loading='lazy' />
                    <div className={styles.tags}>
                        {campaign && (
                            <button type='button' className={styles.campaignBtn} onClick={toggleOpenCampaign}>
                                {t(campaign.name)}
                            </button>
                        )}
                    </div>
                </picture>
                <button className={styles.shareBtn} type='button' onClick={handleShare}>
                    <ShareIcon size={24} />
                </button>
            </div>
            {campaign && (
                <Curtain open={openCampaign} onClose={toggleOpenCampaign}>
                    <CampaignInfo
                        name={campaign.name}
                        count={campaign.count}
                        desc={campaignDescription || ''}
                        onPress={toggleOpenCampaign}
                    />
                </Curtain>
            )}
        </>
    );
};

export default Cover;
