export const handleCopy = (value: string, onDone?: () => void): void => {
    if (navigator?.clipboard && value) {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                if (onDone) onDone();
            })
            .catch(console.error);
        // @ts-ignore
        window?.DD_RUM && window.DD_RUM?.addAction('Copied recording link', { link: value });
    } else {
        // @ts-ignore
        window?.DD_RUM && window.DD_RUM?.addAction('Copy is not supported', { link: value });
        window?.console.error('Copy is not supported');
    }
};

type Share = {
    url: string;
    title?: string;
    img?: string;
    onDone?: () => void;
};

export const handleShareLink = async ({ url, title, img, onDone }: Share) => {
    if (navigator.share) {
        // @ts-ignore
        window.DD_RUM && window.DD_RUM?.addAction('Shared recording link', { link: url });

        const response = img ? await fetch(img) : null;
        const blob = response ? await response.blob() : null;
        const filesArray = blob ? [
            new File(
                [blob],
                title || 'BTS book',
                {
                    type: "image/jpeg",
                    lastModified: new Date().getTime()
                }
            )
        ] : [];

        navigator
            .share({
                title: title || 'BTS book',
                files: filesArray,
                url: url,
            })
            .catch(err => {
                console.error(err);
                handleCopy(url, onDone);
            });
    } else {
        handleCopy(url, onDone);
    }
};
