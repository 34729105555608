import { SvgFactory } from '../../Svg';

const PetsIcon = SvgFactory(
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='black'>
        <path d='M4.5 11.4978C5.88071 11.4978 7 10.3785 7 8.9978C7 7.61709 5.88071 6.4978 4.5 6.4978C3.11929 6.4978 2 7.61709 2 8.9978C2 10.3785 3.11929 11.4978 4.5 11.4978Z' />
        <path d='M9 7.4978C10.3807 7.4978 11.5 6.37851 11.5 4.9978C11.5 3.61709 10.3807 2.4978 9 2.4978C7.61929 2.4978 6.5 3.61709 6.5 4.9978C6.5 6.37851 7.61929 7.4978 9 7.4978Z' />
        <path d='M15 7.4978C16.3807 7.4978 17.5 6.37851 17.5 4.9978C17.5 3.61709 16.3807 2.4978 15 2.4978C13.6193 2.4978 12.5 3.61709 12.5 4.9978C12.5 6.37851 13.6193 7.4978 15 7.4978Z' />
        <path d='M19.5 11.4978C20.8807 11.4978 22 10.3785 22 8.9978C22 7.61709 20.8807 6.4978 19.5 6.4978C18.1193 6.4978 17 7.61709 17 8.9978C17 10.3785 18.1193 11.4978 19.5 11.4978Z' />
        <path d='M17.34 14.3578C16.47 13.3378 15.74 12.4678 14.86 11.4478C14.4 10.9078 13.81 10.3678 13.11 10.1278C13 10.0878 12.89 10.0578 12.78 10.0378C12.53 9.9978 12.26 9.9978 12 9.9978C11.74 9.9978 11.47 9.9978 11.21 10.0478C11.1 10.0678 10.99 10.0978 10.88 10.1378C10.18 10.3778 9.6 10.9178 9.13 11.4578C8.26 12.4778 7.53 13.3478 6.65 14.3678C5.34 15.6778 3.73 17.1278 4.03 19.1578C4.32 20.1778 5.05 21.1878 6.36 21.4778C7.09 21.6278 9.42 21.0378 11.9 21.0378H12.08C14.56 21.0378 16.89 21.6178 17.62 21.4778C18.93 21.1878 19.66 20.1678 19.95 19.1578C20.26 17.1178 18.65 15.6678 17.34 14.3578Z' />
    </svg>,
);

export default PetsIcon;
