import React, { FC, ReactNode, ButtonHTMLAttributes } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import LoadingIcon from "../../icons/Loading";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ReactNode;
    color?: 'red' | 'yellow' | 'green' | 'black' | 'grey';
    size?: 's' | 'm' | 'l';
    className?: string;
    isLoading?: boolean;
}

const RoundButton: FC<Props> = ({ icon, color, size = 'm', className, isLoading, ...attr }) => {
    return (
        <button {...attr} className={cn(styles.roundBtn, { [styles[color || '']]: color, [styles[size || '']]: size, [className || '']: className })} type={attr.type || 'button'}>
            { isLoading ? <LoadingIcon /> : icon}
        </button>
    );
};

export default RoundButton;
