import { doc, DocumentData, getDoc, updateDoc } from "firebase/firestore/lite";
import { db } from '../firebase';
import { FirebaseCollection } from '../utils/firebase';
import { Publisher } from "../types/Publisher";

export const getPublisherQueryKey = 'publisher';
export const getPublishersQueryKey = 'publishers';

export const hydratePublisher = (authorId: string, data: DocumentData): Publisher => {
    const initData: Publisher = {
        id: '',
        name: '',
        types: [],
        location: '',
        contributed: {
            bookIDs: [],
        },
        followers: {
            count: 0,
            userIDs: [],
        }
    };

    return {
        ...initData,
        ...data,
        id: authorId,
    };
};

export const requestGetPublisher = async (id?: string): Promise<Publisher | null> => {
    if (!id) return null;
    const docRef = doc(db, FirebaseCollection.PUBLISHER, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (!data) {
        return null;
    }
    return hydratePublisher(docRef.id, data);
};

export type ReqUpdatePublisher = {
    id: string;
    data: Partial<Publisher>;
}

export const requestUpdatePublisher = async ({id, data}: ReqUpdatePublisher): Promise<void> => {
    const recordRef = doc(db, FirebaseCollection.PUBLISHER, id);
    return updateDoc(recordRef, data);
}

export const requestGetPublishers = async (ids: string[]): Promise<Publisher[]> => {
    if (ids.length < 1) {
        return [];
    }
    return await Promise.all(ids.map(requestGetPublisher)).then(list =>
        list.reduce((list: Publisher[], publisher) => (publisher ? [...list, publisher] : list), []),
    );
};
