import React, { FC, useCallback, useState } from 'react';
import styles from './styles.module.scss';
import StarOutlinedIcon from '../../icons/StarOutlined';
import StarIcon from '../../icons/Star';

const stars = [1, 2, 3, 4, 5];

type Props = {
    selected: number; // 0-5
    size?: number;
    notFilledClassName?: string;
    onSelect?: (value: number) => void;
};

const StarRating: FC<Props> = ({ selected, size = 64, notFilledClassName, onSelect }) => {
    const [hoverRating, setHoverRating] = useState<number>(0);

    const handleMouseOver = useCallback(
        (value: number) => () => {
            setHoverRating(value);
        },
        [],
    );

    const handleMouseLeave = useCallback(() => {
        setHoverRating(0);
    }, []);

    const handleSelectPress = useCallback(
        (value: number) => () => {
            if (onSelect) onSelect(value);
        },
        [onSelect],
    );

    const filledStars = hoverRating || selected;
    const filledArray = new Array(filledStars).fill(0);
    const emptyStars = stars.length - filledStars;
    const emptyArray = new Array(emptyStars).fill(0);
    const starArray = filledArray.concat(emptyArray);

    return (
        <div className={styles.rateStars} onMouseLeave={handleMouseLeave}>
            {starArray.map((_, index) => (
                <button key={index} type='button' onMouseOver={handleMouseOver(index + 1)} onClick={handleSelectPress(index + 1)}>
                    {index < filledStars ? <StarIcon className={styles.filled} size={size} /> : <StarOutlinedIcon className={notFilledClassName} size={size} />}
                </button>
            ))}
        </div>
    );
};

export default StarRating;
