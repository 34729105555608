import { SvgFactory } from '../../Svg';

const BundleIcon = SvgFactory(
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='black'>
        <path d='M7.28028 11.6787C7.28028 10.8555 7.28706 10.0317 7.27757 9.2085C7.27282 8.82358 7.43081 8.5702 7.76643 8.39989C9.63645 7.45073 11.5031 6.49396 13.3717 5.54135C13.9711 5.23535 14.498 5.57181 14.498 6.25858C14.498 7.91666 14.4959 9.57543 14.5 11.2335C14.5007 11.5942 14.3583 11.849 14.0416 12.0103C12.1472 12.9754 10.2548 13.9439 8.35971 14.9069C7.91628 15.1326 7.44098 14.9325 7.31215 14.4735C7.28503 14.3759 7.28232 14.2693 7.28232 14.1661C7.28028 13.3367 7.28096 12.508 7.28096 11.6787H7.28028Z' />
        <path d='M4.38711 9.46535C4.38711 8.64774 4.39253 7.83012 4.38507 7.0125C4.38168 6.62481 4.53695 6.36104 4.87936 6.18727C6.76023 5.23327 8.63771 4.27165 10.5172 3.3135C10.9301 3.10304 11.3566 3.23111 11.5438 3.61742C11.7268 3.99611 11.5743 4.41981 11.1702 4.62681C9.45881 5.50258 7.74745 6.37835 6.03202 7.24512C5.87675 7.32335 5.82997 7.41058 5.83132 7.58296C5.83878 9.06866 5.8381 10.5544 5.83403 12.04C5.83268 12.6354 5.23872 12.9954 4.75053 12.7012C4.48746 12.5427 4.38643 12.2927 4.38711 11.9874C4.38914 11.147 4.38779 10.3058 4.38779 9.46535H4.38711Z' />
        <path d='M1.50207 7.25689C1.50207 6.4275 1.50614 5.59881 1.50004 4.76942C1.49732 4.40042 1.65124 4.1505 1.97398 3.98642C3.86434 3.02619 5.75199 2.05973 7.64032 1.09673C8.04239 0.891803 8.46413 1.02126 8.65195 1.40203C8.83841 1.78003 8.68992 2.2058 8.28513 2.4128C6.56902 3.29204 4.85224 4.16919 3.13207 5.04012C2.99036 5.11212 2.94493 5.19381 2.94561 5.35235C2.95171 6.82627 2.95035 8.3002 2.949 9.77412C2.949 10.1999 2.7266 10.5017 2.3686 10.573C1.90618 10.6651 1.50478 10.3252 1.50275 9.8295C1.49936 8.97174 1.50207 8.11397 1.50207 7.25619V7.25689Z' />
    </svg>,
);

export default BundleIcon;
