import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore/lite';
import { db } from '../firebase';
import { FirebaseCollection } from '../utils/firebase';
import { TSet } from '../types/Sets';

export const getSetQueryKey = 'set';
export const getSetsQueryKey = 'sets';


export const requestGetSet = async (id?: string): Promise<TSet | null> => {
    if (!id) return null;
    const docRef = doc(db, FirebaseCollection.SMART_SETS, id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (!data) {
        return null;
    }
    return {id: docSnap.id, ...data} as TSet;
};

export const requestGetSets = async (market: string): Promise<TSet[]> => {
    if (!market) {
        return [];
    }

    const q = query(collection(db, FirebaseCollection.SMART_SETS), where('shippingCountry', 'array-contains', market));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
        return [];
    }
    return snapshot.docs.reduce((list: TSet[], doc) => {
        return [...list, {id: doc.ref.id, ...doc.data()} as TSet];
    }, []);
};
