import { createContext, FunctionComponent, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { signInAnonymously, User } from 'firebase/auth';
import { datadogRum } from '@datadog/browser-rum';
import { auth } from '../firebase';
import { LangContext } from './lang';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKey } from '../queries';
import { IUser } from '../types/IUser';
import { getOnboardingPassed, setOnboardingPassed } from '../components/common/Onboarding/utils';
import { createUser, requestUpdateUser, requestUser } from "../services/user";
import { getLocalStorage, removeLocalStorage, StorageKey } from "../storage";
import Loader from "../components/Loader";

interface IContextData {
    user: IUser | null;
    auth?: User;
    isAuthenticated: boolean;
    isAuthedAnonymous: boolean;
    isOnboardingPassed: boolean;
    signInAnonymousUser: () => Promise<void>;
    handleOnboardingPass: () => void;
}

const initData: IContextData = {
    isAuthenticated: false,
    isAuthedAnonymous: false,
    user: null,
    isOnboardingPassed: true,
    signInAnonymousUser: () => new Promise(() => null),
    handleOnboardingPass: () => null,
};

export const AuthContext = createContext(initData);

const AuthContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [state, setState] = useState<User>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthedAnonymous, setIsAuthedAnonymous] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<IUser | null>(null);
    const [isOnboardingPassed, setIsOnboardingPassed] = useState<boolean>(getOnboardingPassed());
    const { lang, market } = useContext(LangContext);

    const queryClient = useQueryClient();

    const { mutate: requestCreateNewUser } = useMutation(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.GET_USER, state?.uid]);
        },
    });

    const { mutate: updateUser } = useMutation(requestUpdateUser, {
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.GET_USER, state?.uid]);
        },
    });

    // we are creating new user in the database if user is logged in first time
    const createNewUser = useCallback(() => {
        if (!state?.uid) return;
        const userData = {
            id: state.uid,
            name: state.displayName,
            kidsName: '',
            firstName: state?.displayName?.split(' ')[0] || '',
            lastName: state?.displayName?.split(' ')[1] || '',
            app_shipping_country: market,
            relationshipToChild: '',
            avatar: state.photoURL
                ? {
                      src: state.photoURL,
                  }
                : null,
            isAnonymous: state.isAnonymous,
            following: {
                authorIDs: [],
            },
            createdAt: new Date().toISOString(),
            updatedAt: '',
            library: [],
            invitedRemoteRecords: [] as string[],
        };
        // if user is coming from remote record setup, we need to add book and smart object to the library
        const remoteRecordSetupAccountData = getLocalStorage(StorageKey.REMOTE_RECORD_SETUP_ACCOUNT);
        const { recordId } = remoteRecordSetupAccountData || {};
        if (recordId) {
            userData.invitedRemoteRecords = [recordId];
            removeLocalStorage(StorageKey.REMOTE_RECORD_SETUP_ACCOUNT);
        }

        return requestCreateNewUser(userData);
    }, [state, market, requestCreateNewUser]);

    useQuery<IUser | null>({
        queryKey: [QueryKey.GET_USER, state?.uid],
        queryFn: () => requestUser(state?.uid),
        enabled: !!state && !!state.uid,
        onSuccess: data => {
            // if no user in the database, so creating new user
            if (!data && !state?.isAnonymous) {
                createNewUser();
                return;
            }

            // if user is coming from remote record setup, we need to add book and smart object to the library
            const remoteRecordSetupAccountData = getLocalStorage(StorageKey.REMOTE_RECORD_SETUP_ACCOUNT);
            const { recordId } = remoteRecordSetupAccountData || {};
            if (!state?.isAnonymous && recordId && data) {
                const invitedRemoteRecords = (data?.invitedRemoteRecords || []).filter(id => id === recordId);
                updateUser({ id: data.id, data: { invitedRemoteRecords } });
                removeLocalStorage(StorageKey.REMOTE_RECORD_SETUP_ACCOUNT);
                return;
            }

            setUser(data);
            setIsAuthenticated(true);
            setLoading(false);

            if (window?.analytics && data) {
                window.analytics?.identify(
                    data.id,
                    {
                        email: data.email || state?.email || '',
                        name: data.name,
                    },
                    {
                        integrations: {
                            Intercom: {
                                user_hash: data.intercomUserHash,
                            },
                        },
                    },
                );
            }
            if (data) {
                datadogRum.setUser({
                    id: data.id,
                    email: data.email || state?.email || undefined,
                    name: data?.name || undefined,
                });
            }
        },
        onError: (err: any) => {
            window?.console.error(err);
        },
    });

    useEffect(() => {
        auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                setState(userAuth);
                setIsAuthedAnonymous(userAuth.isAnonymous);
                return;
            } else {
                setIsAuthenticated(false);
                setIsAuthedAnonymous(false);
                setState(undefined);
                setUser(null);
            }
            // if (userAuth && userAuth.isAnonymous) {
            //     setState(userAuth);
            //     setIsAuthedAnonymous(true);
            // }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        auth.languageCode = lang;
    }, [lang]);

    const signInAnonymousUser = useCallback(async () => {
        await signInAnonymously(auth);
    }, []);

    const handleOnboardingPass = useCallback(() => {
        setIsOnboardingPassed(true);
        setOnboardingPassed();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                auth: state,
                isAuthenticated,
                isAuthedAnonymous,
                signInAnonymousUser,
                isOnboardingPassed,
                handleOnboardingPass,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
