import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { getInitials, getRandomColor, calculateSvgSize } from './utils';
import cn from 'classnames';
import UserAvatarIcon from '../../icons/UserAvatar';
import { AvatarSize } from './types';
import { resizeFile } from '../../../utils/cropImg';
import { toBase64 } from '../../../utils/fileToBase64';
import Pencil from '../../icons/Pencil';

interface Props {
    size?: AvatarSize;
    avatar?: string;
    userName?: string;
    bgColor?: string;
    imageClassName?: string;
    onEdit?: (file: File) => void;
}

const Avatar: FC<Props> = ({ size = AvatarSize.L, avatar, userName, bgColor, imageClassName, onEdit }) => {
    const [photo, setPhoto] = useState<string>();
    const [color] = useState<string>(bgColor || getRandomColor());

    const handleChangePhoto = useCallback(
        async (data: ChangeEvent<HTMLInputElement>) => {
            const file = data.target.files?.[0];
            if (file) {
                try {
                    const resizedFile = await resizeFile(file);
                    const b64 = await toBase64(resizedFile);

                    if (onEdit) {
                        onEdit(resizedFile);
                    }
                    setPhoto(b64);
                } catch (err) {
                    window?.console.error(err);
                }
            }
        },
        [onEdit],
    );

    return (
        <div
            className={cn(styles.avatarContainer, {
                [styles[size]]: !!size,
                [styles.withoutAvatar]: !avatar && !userName,
            })}
        >
            {(avatar || photo) && (
                <img
                    src={photo || avatar}
                    alt=''
                    className={cn(styles.avatarImage, { [imageClassName || '']: imageClassName })}
                    loading='lazy'
                />
            )}
            {!avatar && !photo && userName && (
                <div className={styles.avatarInitials} style={{ backgroundColor: bgColor || color }}>
                    {getInitials(userName)}
                </div>
            )}
            {!avatar && !userName && !photo && <UserAvatarIcon className={styles.defIcon} size={calculateSvgSize(size)} />}
            {onEdit && (
                <>
                    <input type='file' size={99999} accept='image/*' onChange={handleChangePhoto} />
                    <span className={styles.editIcon}>
                        <Pencil color='white' />
                    </span>
                </>
            )}
        </div>
    );
};
export default Avatar;
