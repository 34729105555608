import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ICustomMap } from '../../types/ICustomMap';
import { useQuery } from 'react-query';
import { getSetQueryKey, requestGetSet } from '../../services/sets';
import Loader from '../../components/Loader';
import styles from './styles.module.scss';
import { RedirectToNotFoundPage } from '../../components/Redirect';
import Header from '../../components/common/Headers/Header';
import BackButton from '../../components/common/Headers/components/BackButton';
import routes, { routeID } from '../../config/routes';
import Avatar from '../../components/common/Avatar';
import { AvatarSize } from '../../components/common/Avatar/types';
import { AuthContext } from '../../context/auth';
import LogoIcon from '../../components/icons/Logo';
import { TypographySize, TypographyTag } from '../../components/common/Typography/types';
import Typography from '../../components/common/Typography';
import { useTranslation } from 'react-i18next';
import StarRating from '../../components/common/StarRating';
import { getImageByLang, getTextByLang } from '../../utils/langUtils';
import { LangContext } from '../../context/lang';
import ShowMoreText from 'react-show-more-text';
import stylesCommon from '../../styles/common.module.scss';
import Button from '../../components/common/Button';
import GiftBoxIcon from '../../components/icons/GiftBox';
import Cover from './components/Cover';
import BasketIcon from '../../components/icons/Basket';
import { Markets } from '../../config/lang';
import { getMarketCurrencySymbol } from '../Account/components/SelectMarket/utils';
import Stats from './components/Stats';
import { getPublishersQueryKey, requestGetPublishers } from '../../services/publisher';
import { getBooksQueryKey, requestBooks } from '../../services/book';
import { getAuthorsQueryKey, requestAuthors } from '../../services/author';
import ContributorsCards from '../Book/components/ContributorsCards';
import { getSizedImage, ImageSizesValues } from '../../utils/getSizedImage';
import BundleIcon from '../../components/icons/Bundle';
import cn from "classnames";

const Set: FC = () => {
    const { id: setId } = useParams<ICustomMap>();
    const { user } = useContext(AuthContext);

    const { lang, market } = useContext(LangContext);
    const { t } = useTranslation(['sets', 'common']);

    const [quantity, setQuantity] = useState<number>(1);

    const { data: set, isLoading } = useQuery({
        queryKey: [getSetQueryKey, setId],
        queryFn: () => requestGetSet(setId),
        enabled: !!setId,
    });

    const { data: publishers } = useQuery({
        queryKey: [getPublishersQueryKey, setId],
        queryFn: () => requestGetPublishers(set?.publisher || []),
        enabled: !!set?.publisher?.length,
    });

    const { data: books } = useQuery({
        queryKey: [getBooksQueryKey, setId],
        queryFn: () => requestBooks(set?.bundles || []),
        enabled: !!set?.bundles?.length,
    });

    const contributorIds = useMemo(() => set?.contributors?.map(contributor => contributor.id), [set]);

    const { data: contributors } = useQuery({
        queryKey: [getAuthorsQueryKey, setId],
        queryFn: () => requestAuthors(contributorIds || []),
        enabled: !!contributorIds?.length,
    });

    const description = useMemo(() => {
        if (!set) return null;
        return (
            getTextByLang({
                texts: set?.description,
                appLang: lang,
                fallbackLang: set?.inLang?.[0],
            }) || null
        );
    }, [set, lang]);

    const keyWords = useMemo(() => {
        if (!set) return null;
        return set?.keyWords?.find(kw => kw.lang === lang)?.text || set?.keyWords?.find(kw => kw.lang === set?.inLang[0])?.text || null;
    }, [set, lang]);

    console.log('set', set);

    const priceData = useMemo(() => (set?.price?.[market] ? set.price[market] : set?.price?.[Markets.de]), [set, market]);

    const shopLink = useMemo(() => {
        const link = priceData?.shopLink || '';
        return link.replace(':1', `:${quantity}`);
    }, [priceData, quantity]);

    const handleAddQuantity = useCallback(() => {
        setQuantity(prev => prev + 1);
    }, []);

    const handleSubtractQuantity = useCallback(() => {
        if (quantity === 1) return;
        setQuantity(prev => prev - 1);
    }, [quantity]);

    if (isLoading) {
        return <Loader />;
    }

    if (!set) {
        return <RedirectToNotFoundPage />;
    }

    return (
        <section className={styles.root}>
            <Header
                primary
                className={styles.header}
                leftSlot={<BackButton />}
                rightSlot={
                    <Link to={routes.account.root}>
                        <Avatar size={AvatarSize.M} avatar={user?.avatar?.src} />
                    </Link>
                }
            >
                <LogoIcon />
            </Header>
            <div className={styles.content}>
                <Typography className={styles.bookTitle} size={TypographySize.XL} tagName={TypographyTag.h2} bold>
                    {set.title}&nbsp;
                    {set?.subTitle && <span className={styles.subTitle}>{t(set.subTitle)}</span>}
                    {set?.formatsInfo && <span className={styles.formatsInfo}>&nbsp;({t(set.formatsInfo)})</span>}
                </Typography>
                {set?.aggregateRating && (
                    <div className={styles.rating}>
                        {set.aggregateRating.ratingValue && <strong>{set.aggregateRating.ratingValue}&nbsp;</strong>}
                        <StarRating selected={set?.aggregateRating.ratingValue} notFilledClassName={styles.notFilledClassName} size={18} />
                        {set.aggregateRating.ratingCount && <span>&nbsp;({set.aggregateRating.ratingCount})</span>}
                    </div>
                )}

                {set?.categoryTag && (
                    <div className={styles.tags}>
                        <span>
                            <GiftBoxIcon size={16} />
                            {t(set.categoryTag)}
                        </span>
                    </div>
                )}

                {set.cover && (
                    <div className={styles.cover}>
                        <Cover cover={set.cover.src} title={set.title} prodId={set.id} campaign={set.campaign} />
                    </div>
                )}

                <div className={styles.quantityBox}>
                    <button type='button' onClick={handleSubtractQuantity}>
                        &minus;
                    </button>
                    <input type='number' value={quantity} readOnly />
                    <button type='button' onClick={handleAddQuantity}>
                        &#65291;
                    </button>
                </div>

                <div className={styles.priceBox}>
                    {priceData?.salePrice && (
                        <span className={styles.oldPrice}>
                            {getMarketCurrencySymbol(market)} <em>{priceData?.price}</em>
                        </span>
                    )}
                    <span className={styles.price}>
                        {getMarketCurrencySymbol(market)} {priceData?.salePrice || priceData?.price}
                    </span>
                </div>

                <div className={styles.mt16}>
                    <a href={shopLink} target='_blank' rel='noreferrer'>
                        <Button fullWidth color='yellow' icon={<BasketIcon />} size='large'>
                            {set?.inStock ? t('Buy Now') : t('Pre-order now')}
                        </Button>
                    </a>
                </div>

                <div className={styles.subTxt}>
                    {t('Pre-order Price Guarantee')}.&nbsp;
                    <span>{t('Estimated shipping')}</span>&nbsp;
                    <strong>{t('October/November')}</strong>
                </div>
                <div className={styles.btnTxt}>
                    <span>{t('Fulfilled by')} {set?.fulfilled}</span>
                </div>

                <div>
                    <Stats
                        readingAge={set?.age}
                        language={set?.inLang.join(', ')?.toUpperCase()}
                        printFormat={set?.printFormat ? t(`${set?.printFormat}`, { ns: 'common' }) : undefined}
                        genre={set?.genre ? t(`${set?.genre}`, { ns: 'common' }) : undefined}
                        weight={set?.weight}
                        includes={set?.setType ? t('5 Titles') : undefined}
                        publishers={publishers}
                        publicationDate={set?.publicationDate}
                        campaign={set?.campaign}
                        contributors={contributors || []}
                        bookLangs={set?.inLang}
                        printLength={set?.printLength}
                        listenTime={set?.listenTime}
                        recordableAudioBook={set?.recordableAudioBook}
                        activityBook={set?.activityBook}
                        ISBN={set?.ISBN}
                        dimensions={set?.dimensions}
                    />
                </div>

                <div className={styles.mt24}>
                    <Typography size={TypographySize.L} tagName={TypographyTag.h3}>
                        {t('aboutSet')}
                    </Typography>
                    {description && (
                        <div className={styles.txt}>
                            <ShowMoreText more={t('more', { ns: 'common' })} less={t('showLess', { ns: 'common' })} className={stylesCommon.moreLessText}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </ShowMoreText>
                        </div>
                    )}

                    {keyWords && (
                        <div className={styles.keyWords}>
                            {keyWords.map((tag, idx) => (
                                <Button size='xs' color='gray' key={idx} className={styles.tag}>
                                    {tag}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>

                {!!books?.length && (
                    <div className={styles.bundles}>
                        <Typography size={TypographySize.L} tagName={TypographyTag.h3}>
                            {t("What's included in set")}
                        </Typography>

                        {books.map(book => {
                            let image = getImageByLang({
                                images: [...(book?.coverImgEbook || []), ...(book?.coverImgAudiobook || [])],
                                appLang: lang,
                                fallbackLang: book?.inLanguage[0],
                            });
                            image = image || book?.coverImgSquare?.src || book?.coverImg?.src || null;
                            const sizedCover = getSizedImage(image, ImageSizesValues['280x280']);

                            return (
                                <div key={book.id} className={styles.bfook}>
                                    <Link to={routes.book.replace(routeID, book.id)}>
                                        <div className={styles.bookBox}>
                                            <picture>
                                                {sizedCover?.webp && <source srcSet={sizedCover.webp} type='image/webp' />}
                                                <img src={sizedCover?.jpg || ''} alt={book.name} loading='lazy' />
                                            </picture>
                                            <div>
                                                <Typography
                                                    className={styles.title}
                                                    size={TypographySize.L}
                                                    tagName={TypographyTag.h3}
                                                    bold
                                                >
                                                    {book.name}&nbsp;
                                                    {book?.subTitle && <span className={styles.subTitle}>{t(book.subTitle)}</span>}
                                                    {book?.formatsInfo && <span className={styles.formatsInfo}>&nbsp;({t(book.formatsInfo)})</span>}
                                                </Typography>
                                                {book?.aggregateRating && (
                                                    <div className={styles.rating}>
                                                        <strong>{book.aggregateRating.ratingValue}&nbsp;</strong>
                                                        <StarRating
                                                            selected={book.aggregateRating.ratingValue}
                                                            notFilledClassName={styles.notFilledClassName}
                                                            size={18}
                                                        />
                                                        {book.aggregateRating.reviewCount && (
                                                            <span>&nbsp;({book.aggregateRating.reviewCount})</span>
                                                        )}
                                                    </div>
                                                )}
                                                {book?.categoryTag && (
                                                    <div className={cn(styles.tags, styles.bundleTag)}>
                                                        <span>
                                                            <BundleIcon size={16} />
                                                            {t(book?.categoryTag)}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                )}

                {!!contributors?.length && (
                    <>
                        <div className={styles.mt24}>
                            <ContributorsCards contributors={contributors} />
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Set;
