import React, { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from 'react';
import Typography from '../../../Typography';
import { TypographySize, TypographyTag } from '../../../Typography/types';
import styles from './styles.module.scss';
import Avatar from '../../../Avatar';
import { AvatarSize } from '../../../Avatar/types';
import InputField from '../../../InputField';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button';
import ShareIcon from '../../../../icons/ShareIcon';
import { AuthContext } from '../../../../../context/auth';
import { useMutation, useQuery } from 'react-query';
import { requestCreateRecord, requestUpdateRecord, requestUploadRecordAvatar } from '../../../../../services/record';
import { Lang } from '../../../../../config/lang';
import { getSmartObjectQueryKey, requestSmartObject, requestUpdateSmartObject } from '../../../../../services/smatrObject';
import { getSecret } from '../../utils';
import routes from '../../../../../config/routes';
import { handleShareLink } from '../../../../../utils/copyShare';
import { toast } from 'react-toastify';

type Props = {
    recordLang: string;
    bookId: string;
    onDone: () => void;
};
const NarratorDetails: FC<Props> = ({ bookId, recordLang, onDone }) => {
    const { t } = useTranslation('common');
    const { user } = useContext(AuthContext);
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [name, setName] = useState<string>('');
    const [relationship, setRelationship] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const { mutateAsync: createRecording } = useMutation(requestCreateRecord);
    const { mutateAsync: uploadRecordAvatar } = useMutation(requestUploadRecordAvatar);
    const { mutateAsync: updateRecord } = useMutation(requestUpdateRecord);
    const { mutateAsync: updateSmartObject } = useMutation(requestUpdateSmartObject);

    const smartObjectId = useMemo(() => user?.library.find(book => book.smartBookId === bookId)?.smartObjectId, [bookId, user]);

    const { data: smartObject } = useQuery({
        queryKey: [getSmartObjectQueryKey, smartObjectId],
        queryFn: () => requestSmartObject(smartObjectId || ''),
        enabled: !!smartObjectId,
    });

    const handleChangePhoto = useCallback(async (file: File) => {
        if (file) {
            setSelectedFile(file);
        }
    }, []);

    const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const handleRelationshipChange = useCallback(
        (val: string) => () => {
            setRelationship(val);
        },
        [],
    );

    const relationships = useMemo(
        () => [
            t('relationships.mother'),
            t('relationships.father'),
            t('relationships.grandfather'),
            t('relationships.grandmother'),
            t('relationships.aunt'),
            t('relationships.uncle'),
            t('relationships.brother'),
            t('relationships.sister'),
            t('relationships.other'),
        ],
        [t],
    );

    const handleSharePress = useCallback(async () => {
        if (name && smartObject) {
            try {
                setLoading(true);
                const recordId = await createRecording({
                    narratorName: name,
                    smartObject: smartObject.id,
                    smartObjectBook: bookId,
                    lang: recordLang as Lang,
                    familyRelationship: relationship,
                    recordingType: 'remote',
                    canEdit: true,
                    narratorUserId: user?.id,
                });

                if (selectedFile) {
                    const avatarUrl = await uploadRecordAvatar({ recordId, file: selectedFile });
                    await updateRecord({ recordId, payload: { narratorAvatar: { src: avatarUrl } } });
                }

                let secret = smartObject.remoteRecSecret;
                if (!secret) {
                    // @ts-ignore
                    window?.DD_RUM &&
                        // @ts-ignore
                        window?.DD_RUM?.addAction('Setting remoteRecSecret code to smart object', { smartObjectId: smartObject.id });
                    secret = getSecret();
                }

                await updateSmartObject({
                    id: smartObject.id,
                    data: {
                        remoteRecSecret: secret,
                        recordingsList: [...smartObject.recordingsList, recordId],
                        ownerDetails: {
                            name: user?.name || `${user?.firstName} ${user?.lastName}`,
                            email: user?.email || '',
                            kidsName: user?.kidsName || '',
                            avatar: {
                                src: user?.avatar?.src,
                            },
                        },
                    },
                });

                const shareUrl = `${window.location.origin}${routes.remoteRecord.root}?recKey=${recordId}-${secret}`;

                handleShareLink({
                    url: shareUrl,
                    title: '',
                    onDone: () => {
                        toast.success(t('copiedToClipboard'));
                    },
                });

                onDone();
            } catch (err) {
                window.console.error(err);
                toast.error(t('errorHappened'));
            } finally {
                setLoading(false);
            }
        }
    }, [
        bookId,
        smartObject,
        recordLang,
        name,
        selectedFile,
        createRecording,
        uploadRecordAvatar,
        updateRecord,
        user,
        updateSmartObject,
        t,
        onDone,
        relationship,
    ]);

    return (
        <>
            <div className={styles.top}>
                <Typography size={TypographySize.L} tagName={TypographyTag.h5}>
                    {t('remoteRecord.title')}
                </Typography>
                <Typography size={TypographySize.S}>{t('remoteRecord.desc')}</Typography>
            </div>
            <div className={styles.avatar}>
                <Avatar userName={name} size={AvatarSize.XL2} onEdit={handleChangePhoto} />
            </div>
            <div className={styles.field}>
                <InputField
                    label={t('remoteRecord.narratorName')}
                    bgColor='white'
                    fieldSize='large'
                    type='text'
                    value={name}
                    onChange={handleNameChange}
                    required
                />
            </div>
            <Typography size={TypographySize.M} tagName={TypographyTag.p} className={styles.subTitle}>
                {t('remoteRecord.subTitle')}
            </Typography>
            <div className={styles.relationships}>
                {relationships.map((rel, i) => (
                    <Button key={i} size='xs' color={relationship !== rel ? 'gray' : undefined} onClick={handleRelationshipChange(rel)}>
                        {rel}
                    </Button>
                ))}
            </div>
            <Button
                textUpperCase
                fullWidth
                isLoading={isLoading}
                onClick={handleSharePress}
                color={name ? 'yellow' : 'gray'}
                size='large'
                icon={<ShareIcon />}
                disabled={!name}
            >
                {t('remoteRecord.shareInviteLink')}
            </Button>
        </>
    );
};

export default NarratorDetails;
