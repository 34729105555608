import React, { FC, ReactNode, useCallback } from "react";
import cn from 'classnames';

import styles from './styles.module.scss';

export type RadioButtonGroupOption = {
    icon?: ReactNode;
    label?: string | ReactNode;
    value: string | number;
};

type Props = {
    options: RadioButtonGroupOption[];
    value: string | number;
    className?: string;
    onChange: (value: string) => void;
}

const RadioButtonGroup: FC<Props> = ({ options, value, className, onChange }) => {
    const handleOptionChange = useCallback((val: any) => () => {
        if (val !== value) {
            onChange(val);
        }
    }, [value, onChange]);

    return (
        <div className={cn(styles.radioGroup, {[className || '']: className})}>
            {options.map(option => (
                <button
                    type='button'
                    key={option.value}
                    className={cn(styles.radioBtn, { [styles.selected]: value === option.value })}
                    onClick={handleOptionChange(option.value)}
                >
                    {option.icon && <span className={styles.icon}>{option.icon}</span>}
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default RadioButtonGroup;
